import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DashboardLayoutSetting from 'src/layouts/DashboardLayoutSettings';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen1';
import AuthGuard from 'src/components/Guards/AuthGuard';
import SettingsGuard from 'src/components/Guards/SetingsGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import HomeView from 'src/views/home/HomeView';
import SettingsLayout from 'src/views/Test1';
// import TasksDetail from 'src/views/AllTasks/TaskDetailView';
import { Dialog } from '@material-ui/core';
import ReportsGuard from 'src/components/Guards/ReportsGuard';
import HODGuard from 'src/components/Guards/HODGuard';
import HRGuard from 'src/components/Guards/HRGuard';

import HeadGuard from 'src/components/Guards/HeadGuard';
import DavanaGuard from 'src/components/Guards/DavanaGuard';
// import Loader from 'src/components/LoadingScreen/LoadingScreen';
import Loading from 'src/components/Loading'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loading open={true} />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                  {/* <Route
                    element={
                      <Dialog open>
                        <TasksDetail />
                      </Dialog>
                    }
                    path="/app/admin/faculty/taskdetail"
                  />*/}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path:
      '/app/admin/attendanceNew/students/:course_code/:section/:lab_batch/:task_id',
    component: lazy(() =>
      import('src/views/AttendanceNew/ClassDetailView/StudentIndex')
    )
  },      
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  // {
  //   exact: true,
  //   path: '/policy/privacy/privacypolicy',
  //   component: lazy(() => import('src/views/PrivacyPolicy'))
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signUp',
    component: lazy(() => import('src/views/auth/LoginView/SignUp'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,

    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() =>
      import('src/views/auth/LoginView/Confirm_forgot_password')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirmSignUp',
    component: lazy(() => import('src/views/auth/LoginView/confirmSignUp'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirmSignUpp',
    component: lazy(() =>
      import('src/views/auth/LoginView/confirmSignUpafterLogin')
    )
  },

  {
    path: '/app/admin/settings',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
     
      {
        exact: true,
        path: '/app/admin/settings/GeneralSettings/Personal',
        layout:SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/PersonalSetup'))
      },
      {
        exact: true,
        path: '/app/admin/settings/GeneralSettings/Company',
        layout:SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstitutionNew'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Integration',
        layout:SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Integrations/Integration'))
      },
      {
        exact: true,
        path: '/app/admin/settings/email/Email',
        layout:SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/EmailTemplate'))
      },
    
    
      {
        exact: true,
        path: '/app/admin/settings/programs',
       layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Programs'))
      },
     
    
     
      
     
      {
        exact: true,
        guard: SettingsGuard,
        layout : SettingsLayout,

        path: '/app/admin/settings/user/user_management',
        component: lazy(() => import('src/views/UserManagement'))
      },

      
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/admission/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/data/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/user/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/Compliances/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/email/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        guard: SettingsGuard,
        layout : SettingsLayout,
        exact: true,
        path: '/app/admin/settings/general',
        component: lazy(() => import('src/views/Setup/Details'))
      },
     
      
      
      
     
     
      {
        exact: true,
        path: '/app/admin/settings/quota',
       layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Quota'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/org/stream',
      // layout : SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/Setup/Stream'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/stream/addStream',
      //  layout : SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/InstSetup/Stream/AddStreamNew'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/stream/:id',
      //  layout : SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/InstSetup/Stream/AddStream'))
      // },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream/:id',
     layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream/AddStreamNew'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream/:id/edit',
        layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream/StreamEditView'))
      },
    //   {
    //     exact: true,
    //     path: '/app/admin/settings/org/batch',
    //     guard: SettingsGuard,
    //  layout : SettingsLayout,
    //     component: lazy(() => import('src/views/Setup/Batch'))
    //   },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/addBatch',
     layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/AddBatch'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/:id',
      layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/BatchDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/:id/edit',
        layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/BatchEditView'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/org/academicyear',
      //  layout : SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/Setup/AcademicYear'))
      // },

      
    
      {
        exact: true,
        path: '/app/admin/settings/quota',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Quota'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/Academic/stream/addStream',
      //   layout: SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/InstSetup/Stream/AddStream'))
      // },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream/:id',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream/Details'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream/:id/edit',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream/StreamEditView'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch',
        layout: SettingsLayout,
        component: lazy(() => import('src/views/InstSetup/Batch'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/addBatch',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/AddBatch'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/:id',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/BatchDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/:id/edit',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/BatchEditView'))
      },

      {
        exact: true,
        path: '/app/admin/settings/Academic/academicyear',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/AcademicYear'))
      },

      {
        exact: true,
        path: '/app/admin/settings',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: () => <Redirect to="/app/admin/settings/programs" />
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '/app/admin/profile',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/admin/profile',
        component: lazy(() => import('src/views/StudentProfile'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Education',
        component: lazy(() => import('src/views/EducationEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/TechnicalSkills',
        component: lazy(() => import('src/views/TechnicalSkillsEdit'))
      },

      {
        exact: true,
        path: '/app/admin/profile/Experience',
        component: lazy(() => import('src/views/ExperienceEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Projects',
        component: lazy(() => import('src/views/ProjectsEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Social',
        component: lazy(() => import('src/views/StudentProfile/SocialEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Languages',
        component: lazy(() => import('src/views/StudentProfile/LanguageEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Accomplishments',
        component: lazy(() => import('src/views/AccomplishmentsDetailsEdit'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/admin/academics/:id/:doc_id',
        component: lazy(() => import('src/views/Academics/ClassDetailView/EditResource'))
      },
      {
        exact: true,
        path: '/app/admin/tenent_info',
        component: lazy(() => import('src/views/Setup/Details'))
      },
      {
        exact: true,
        path: '/app/admin/idcard',
        component: lazy(() => import('src/views/IDCard'))
      },
      {
        exact: true,
        path: '/app/admin/movementregister',
        component: lazy(() => import('src/views/MovementRegister'))
      },
      {
        exact: true,
        path: '/app/admin/organizer',
        component: lazy(() => import('src/views/FacultyDetailView/CandidateDetails'))
      },
      {
        exact: true,
        path: '/app/admin/leaves',
        component: lazy(() => import('src/views/Leaves'))
      },
      {
        exact: true,
        path: '/app/admin/home',
        component: lazy(() => import('src/views/HomeView'))
      },
      {
        exact: true,
        path: '/app/admin/allevents',
        component: lazy(() => import('src/views/AllEvents'))
      },
      {
        exact: true,
        path: '/app/admin/allevents/:id',
        component: lazy(() => import('src/views/EventDetail'))
      },
      {
        exact: true,
        path: '/app/admin/academics',
        component: lazy(() => import('src/views/Academics/Courses'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/previous_academics',
      //   component: lazy(() => import('src/views/Academics/PreviousAcademics'))
      // },
      {
        exact: true,
        path: '/app/admin/exam',
        component: lazy(() => import('src/views/Tasks'))
      },
      {
        exact: true,
        path: '/app/admin/academics/tasks/dashboard/:seating_id',
        component: lazy(() => import('src/views/Tasks/StudentList'))
      },
      {
        exact: true,
        path: '/app/admin/academics/tasks/examAttendance/:seating_id',
        component: lazy(() => import('src/views/Tasks/Test'))
      },
      {
        exact: true,
        path: '/app/admin/academics/tasks',
        component: lazy(() => import('src/views/AllTasks'))
      },
      {
        exact: true,
        path: '/app/admin/AllReports',
        component: lazy(() => import('src/views/AllReports'))
      },
      {
        exact: true,
        path: '/app/admin/AdminReports',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/admin'))
      },
      {
        exact: true,
        path: '/app/admin/HRReports',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/HRReports'))
      },

      {
        exact: true,
        path: '/app/admin/academics/feedbacks',
        component: lazy(() => import('src/views/Feedback'))
      },
      {
        exact: true,
        path: '/app/admin/academics/feedbacks/:id',
        component: lazy(() =>
          import(
            'src/views/Feedback/AddFeedbackQuestions/AddFeedback/AddQuestionPaper'
          )
        )
      },
      {
        exact: true,
        path: '/app/admin/academics/feedback_schedule',
        component: lazy(() =>
          import(
            'src/views/Feedback/AddFeedbackQuestions/AddFeedback/ScheduleFeedback'
          )
        )
      },
      // {
      //   exact: true,
      //   path: '/app/admin/academics/report/:id/:iano',
      //   component: lazy(() =>
      //     import(
      //       'src/views/Academics/ClassDetailView/InternalReport/InternalReport'
      //     )
      //   )
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/academics/report/activity/:id/:scheduleid',
      //   component: lazy(() =>
      //     import(
      //       'src/views/Academics/ClassDetailView/ActivityReport/InternalReport'
      //     )
      //   )
      // },
      {
        exact: true,
        path: '/app/ClassIncharge/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Counselling/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/ClassIncharge',
        component: lazy(() => import('src/views/ClassIncharge'))
      },
      {
        exact: true,
        path: '/app/Groups',
        component: lazy(() => import('src/views/Groups'))
      },
      {
        exact: true,
        path: '/app/Mentor/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/MyTasks/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Task/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Document/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Feedback/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Course/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Configuration/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Batch/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/People/:type/:page_id/:app_name/:module_name/:entity',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Resources/:type/:page_id/:app_name/:module_name/:entity',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Circulars/:type/:page_id/:app_name/:module_name/:entity',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Classes/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/admin/Add/People/:type/:page_id/:module_name/:app_name/:entity',
        component: lazy(() => import('src/views/App/AddTask/AddEmployee'))
      },
      {
        exact: true,
        path: '/app/Event/:type/:page_id/:app_name/:module_name/:entity',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Meeting/:type/:page_id/:app_name/:module_name/:entity',
        component: lazy(() => import('src/views/App'))
      },
      {
        exact: true,
        path: '/app/Meeting/:type/:page_id/:app_name/:module_name/:entity/:data_id',
        component: lazy(() => import('src/views/App/DetailView/AppDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/mentors',
        component: lazy(() => import('src/views/Mentors'))
      },
      {
        exact: true,
        path: '/app/admin/more',
        component: lazy(() => import('src/views/More'))
      },
      {
        exact: true,
        path: '/app/admin/attendanceNew',
        component: lazy(() => import('src/views/AttendanceNew'))
      },
      {
        exact: true,
        path: '/app/admin/facultyAttendance',
        component: lazy(() => import('src/views/MyPresence'))
        // component: lazy(() => import('src/views/FacultyAttendance'))
      },
      {
        exact: true,
        path: '/app/admin/attendanceNew/:course_code/:time_table_id/:section/:lab_batch',
        component: lazy(() => import('src/views/AttendanceNew/ClassDetailView'))
      },
      
      {
        exact: true,
        path: '/app/admin/mentors/student_detail/:id/:layout_id',
        component: lazy(() => import('src/views/Mentors/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admin/mentors/feedback',
        component: lazy(() => import('src/views/Mentors/FeedBackForm'))
      },

      {
        exact: true,
        path: '/app/admin/academics/:id',
        component: lazy(() => import('src/views/Academics/ClassDetailView'))
      },
     
      {
        exact: true,
        path: '/app/admin/academics/:id/attendance',
        component: lazy(() =>
          import('src/views/Academics/ClassDetailView/AttendanceDesktop')
        )
      },
      {
        exact: true,
        path: '/app/payroll/attendancenew',
        guard:HRGuard,

        component: lazy(() => import('src/views/AttendenceDesktopPayroll'))
      },
      {
        exact: true,
        path: '/app/payroll/attendance',
        guard:HRGuard,

        component: lazy(() => import('src/views/Attendance/List'))
      },
      {
        exact: true,
        path: '/app/admin/academics/:id/mobile',
        component: lazy(() => import('src/views/AcademicsMobile'))
      },
      {
        exact: true,
        path: '/app/admin/leaveApplications/:page_id',
        guard:HRGuard,

        component: lazy(() => import('src/views/Employee'))
      },
      {
        exact: true,
        path: '/app/admin/movement_register/:page_id',
        guard:HRGuard,

        component: lazy(() => import('src/views/Employee'))
      },
      {
        exact: true,
        path: '/app/admin/Employees/:page_id',
        guard:HRGuard,

        component: lazy(() => import('src/views/Employee'))
        
      },
      {
        exact: true,
        path: '/app/admin/academics/:id/CourseExitSurvey/:type',
        component: lazy(() =>
          import('src/views/Academics/ClassDetailView/CourseExitSurvey')
        )
      },
      {
        exact: true,
        path: '/app/admin/academics/:id/FeedBack/:type',
        component: lazy(() =>
          import('src/views/Academics/ClassDetailView/FeedBack')
        )
      },
      {
        exact: true,
        path: '/app/admin/academics/:id/lesson_plan',
        component: lazy(() =>
          import(
            'src/views/Academics/ClassDetailView/LessonPlan/BulkUploadLessonPlan'
          )
        )
      },
      {
        exact: true,
        path: '/app/admin/academics/:cc/:sec/:id/:course_id/addQuestionPaper',
        component: lazy(() =>
          import(
            'src/views/Academics/QuestionPaper'
          )
        )
      },
    
      // {
      //   exact: true,
      //   path: '/app/admin/academics/:cc/:sec/:id/addAssignment',
      //   component: lazy(() =>
      //     import(
      //       'src/views/Academics/AddAssignment/AddPattern'
      //     )
      //   )
      // },     
      // {
      //   exact: true,
      //   path: '/app/admin/academics/:cc/:sec/:id/addActivity',
      //   component: lazy(() =>
      //     import(
      //       'src/views/Academics/AddActivity/AddPattern'
      //     )
      //   )
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/academics/:cc/:sec/:id/addRubrics',
      //   component: lazy(() =>
      //     import(
      //       'src/views/Academics/ClassDetailView/AddRubricsPattern/AddQuestionPaper'
      //     )
      //   )
      // },

      {
        exact: true,
        path: '/app/admin/academics/QuestionPaper/:cc/:sec/:qp/add_attendance',
        component: lazy(() =>
          import('src/views/Academics/ClassDetailView/InternalAttendance')
        )
      },
      {
        exact: true,
        path: '/app/admin/academics/QuestionPaper/:cc/:sec/:qp/add_marks',
        component: lazy(() =>
          import('src/views/Academics/ClassDetailView/BulkUploadIAMarks')
        )
      },
    
      // {
      //   exact: true,
      //   path:
      //     '/app/admin/academics/QuestionPaper/labIA/:cc/:sec/:qp/:course_reg_id/add_marks',
      //   component: lazy(() =>
      //     import('src/views/Academics/ClassDetailView/BulkUploadLabIAMarks')
      //   )
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/academics/:cc/:sec/bulk_upload/:id/assignment',
      //   component: lazy(() =>
      //     import('src/views/Academics/ClassDetailView/BulkUploadAssignment')
      //   )
      // },
      {
        exact: true,
        path: '/app/admin/academics/:cc_id/:cc/:sec/upload_marks/:task_id/see_marks',
        component: lazy(() =>
          import('src/views/Academics/ClassDetailView/UploadSeeMarks')
        )
      },
      {
        exact: true,
        path: '/app/admin/academics/:cc_id/:sec/upload_marks/:task_id/ia_marks',
        component: lazy(() =>
          import('src/views/Academics/ClassDetailView/UploadIAMarks')
        )
      },
     
      // {
      //   exact: true,
      //   path:
      //     '/app/admin/academics/:cc_id/:sec/upload_marks/:task_id/assignment_marks',
      //   component: lazy(() =>
      //     import('src/views/Academics/ClassDetailView/UploadAssignmentMarks')
      //   )
      // },
      // {
      //   exact: true,
      //   path:
      //     '/app/admin/academics/:cc_id/:sec/upload_marks/:task_id/activity_marks',
      //   component: lazy(() =>
      //     import('src/views/Academics/ClassDetailView/UploadActivityMarks')
      //   )
      // },
      // {
      //   exact: true,
      //   path:
      //     '/app/admin/academics/:cc_id/:sec/upload_marks/:task_id/lab_ia_marks',
      //   component: lazy(() =>
      //     import('src/views/Academics/ClassDetailView/UploadLabMarks')
      //   )
      // },
      {
        exact: true,
        path: '/app/admin/academics/:cc/:sec/bulk_upload/:id/see_marks',
        component: lazy(() =>
          import('src/views/Academics/ClassDetailView/BulkUploadSEE')
        )
      },
      // {
      //   exact: true,
      //   path: '/app/admin/academics/:cc/:sec/bulk_upload/:id/activity',
      //   component: lazy(() =>
      //     import('src/views/Academics/ClassDetailView/BulkUploadActivity')
      //   )
      // },
      {
        exact: true,
        path: '/app/admin/counselling/:usn/:counselling_id',
        component: lazy(() =>
          import('src/views/Mentors/StudentDetailView/CounsellingTest')
        )
      },
      {
        exact: true,
        path: '/app/admin/counselling',
        component: lazy(() => import('src/views/Mentors/CounsellingByFaculty'))
      },
      {
        exact: true,
        path: '/app/admin/payments',
        component: lazy(() => import('src/views/Payments'))
      },

      {
        exact: true,
        path: '/app/admin/events',
        component: lazy(() => import('src/views/Event'))
      },
      {
        exact: true,
        path: '/app/admin/eventStudents',
        component: lazy(() => import('src/views/Event/AllStudents'))
      },
      {
        exact: true,
        path: '/app/admin/events/davana',
        guard: DavanaGuard,
        component: lazy(() => import('src/views/Davana'))
      },
      {
        exact: true,
        path: '/app/admin/events/:id',
        component: lazy(() =>
          import('src/views/Event/Placements/CompanyProfile')
        )
      },
      {
        exact: true,
        path: '/app/admin/events/davana/:id',
        guard: DavanaGuard,
        component: lazy(() =>
          import('src/views/Davana/Placements/CompanyProfile')
        )
      },
      {
        exact: true,
        path: '/app/admin/events/registration/:id',
        guard: HODGuard,
        component: lazy(() => import('src/views/Event/Placements/Register'))
      },
      {
        exact: true,
        path: '/app/admin/events/editregistration/:id',
        component: lazy(() =>
          import('src/views/Event/Placements/Register/StudentsEdit')
        )
      },
      {
        exact: true,
        path: '/app/admin/faculty',
        component: lazy(() => import('src/views/Faculty'))
      },

      {
        exact: true,
        path: '/app/admin/faculty/:id',
        component: lazy(() => import('src/views/Faculty/EmployeeDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/employee/addEmployee',
        component: lazy(() => import('src/views/Faculty/AddEmployee'))
      },
      {
        exact: true,
        path: '/app/admin/employee/addEmployee/:id',
        component: lazy(() => import('src/views/Faculty/AddEmployeeContinue'))
      },
      {
        exact: true,
        path: '/app/admin/resume',
        component: lazy(() => import('src/views/Resume'))
      },
      {
        exact: true,
        path: '/app/admin/employee/:id',
        component: lazy(() => import('src/views/Faculty/EmployeeDetailView'))
      },
      // {
      //   exact: true,
      //   path: '/app/fee/collectfee/Order/:id',
      //   component: lazy(() => import('src/views/Mentors/Payments/CollectFee'))
      // },

      {
        exact: true,
        path: '/app/admin/questionpaperpdf',
        component: lazy(() => import('src/views/Event/ApplicationForm'))
      },
      {
        exact: true,
        path: '/app/admin/academics/:id/:usn/studentDetail',
        component: lazy(() => import('src/views/StudentReport'))
      },

      {
        exact: true,
        path: '/app/admin/payroll',
        component: lazy(() => import('src/views/MyPayroll'))
      },
     
      {
        exact: true,
        path: '/app/admin/calender',
        component: lazy(() => import('src/views/Calendar'))
      },
     
      {
        exact: true,
        path: '/app/admin/reports/InternalReports',

        component: lazy(() =>
          import('src/views/AllReports/InternalReportFaculty')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ProgramExitSurveyReport',
        huard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/ProgramExitSurveyReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ProgramExitSurvey',
        huard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/ProgramExitSurveyNewReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/AcademicYearEndSurvey',
        huard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/AcademicYearEndSurvey')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ResultAnalysisReport',

        component: lazy(() =>
          import('src/views/AllReports/ResultAnalysisReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/CIEDetailedReport',

        component: lazy(() =>
          import('src/views/AllReports/CIEDetailedReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/CoursePlanReport',

        component: lazy(() =>
          import('src/views/AllReports/CoursePlanReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/AssignmentReport',

        component: lazy(() =>
          import('src/views/AllReports/AssignmentReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/CESReport',

        component: lazy(() =>
          import('src/views/AllReports/CESReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/MappingReport',

        component: lazy(() =>
          import('src/views/AllReports/MappingReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ClassReport',

        component: lazy(() =>
          import('src/views/AllReports/ClassReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ActivityReport',

        component: lazy(() =>
          import('src/views/AllReports/ActivityReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/SEFReport',

        component: lazy(() =>
          import('src/views/AllReports/FeedbackReports')
        )
      },

      {
        exact: true,
        path: '/app/admin/reports/AttendanceReport',

        component: lazy(() =>
          import('src/views/AllReports/AttendanceReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/GradingReport',

        component: lazy(() =>
          import('src/views/AllReports/GradingReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/IAReport',

        component: lazy(() =>
          import('src/views/AllReports/IAReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/AttendanceReport',

        component: lazy(() => import('src/views/AllReports/AttendanceReports'))
      },
      {
        exact: true,
        path:
          '/app/admin/attendanceNew/students1/:course_code/:section/:lab_batch/:task_id',
        component: lazy(() =>
          import('src/views/AttendanceNew/ClassDetailView/StudentIndex1')
        )
      }, 
      {
        exact: true,
        path: '/app/admin/reports/NotConductedReport',

        component: lazy(() => import('src/views/AllReports/NotConductedReport'))
      },
      {
        exact: true,
        path: '/app/admin/employee/:id/:layout_id',
        guard:HeadGuard,
        component: lazy(() => import('src/views/CandidateDetails'))
      },
      {
        exact: true,
        path: '/app/admin/faculty/:id/:layout_id',
        guard:HRGuard,
        component: lazy(() => import('src/views/CandidateDetailsHR'))
      },
      {
        exact: true,
        path: '/app/admin/reports/CIEResultAnalysis',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/CieResultAnalysis'))
      },
      {
        exact: true,
        path: '/app/admin/reports/CIEDetail',
        guard:HeadGuard,

        component: lazy(() => import('src/views/AllReports/CIEDetailReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/StudentAttendance',
        guard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/StudentAttendanceReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/FacultyWorkload',
        guard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/FacultyWorkloadReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/StudentToSection',
        guard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/StudentSectionReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/StudentMentorReport',
        guard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/StudentMentorReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/GradingLagReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/GradingLagReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/CounsellingLagReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/CounsellingLagReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/SelfLagReport',

        component: lazy(() => import('src/views/AllReports/SelfLagReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/FacultyLagReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/FacultyLagReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/InternalLagReport',

        component: lazy(() => import('src/views/AllReports/LagReport'))
      },
      {
        exact: true,
        path: '/app/admin/hr_reports/leave_balance_report',

        component: lazy(() => import('src/views/AllReports/LeaveBalanceReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/AttendanceLagReport',
        guard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/AttendanceLagReportNew')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/AssignmentLagReport',

        component: lazy(() =>
          import('src/views/AllReports/AssignmentLagReports')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ActivityLagReport',

        component: lazy(() => import('src/views/AllReports/ActivityLagReports'))
      },
      {
        exact: true,
        path: '/app/admin/reports/CourseExitSurveyLagReport',
        guard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/CourseExitSurveyLagReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/attendance/timeSheet',
        component: lazy(() => import('src/views/AllReports/TimeSheetReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/attendance/datewise_emp_attendance',
        component: lazy(() =>
          import('src/views/AllReports/DateWiseAttendanceMonthlyReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/attendance/custom_timeSheet',
        component: lazy(() => import('src/views/AllReports/CustomTimeSheetReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/attendance/dailyAttendanceReport',
        component: lazy(() => import('src/views/AllReports/DailyAttendanceReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/attendance/EmployeeSummaryReport',
        component: lazy(() => import('src/views/AllReports/EmployeeSummaryReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/attendance/lateTimeReport',
        component: lazy(() => import('src/views/AllReports/LateTimeReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/attendance/monthly_contract_detail',
        component: lazy(() =>
          import('src/views/AllReports/AttendanceMonthlyReportContractDetail')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/attendance/monthly_contract',
        component: lazy(() =>
          import('src/views/AllReports/AttendanceMonthlyReportContract')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/attendance/monthly',
        component: lazy(() =>
          import('src/views/AllReports/AttendanceMonthlyReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/COReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/CourseCOReportBatch'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/PublicationReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/PublicationsReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/ConferenceReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/ConferenceReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/BookPublicationReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/BookPublicationReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/PatentReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/PatentReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/CertificationReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/CertificationReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/ProjectFundReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/ProjectFundReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/GuidanceReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/GuidanceReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/AssignmentReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/AssignmentReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/SeminarReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/SeminarReport'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating/:tt_id/:session_id/session/:seating_id/room',

        component: lazy(() => import('src/views/OnlineExamRoom'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/ConsultancyReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/ConsultancyReport'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating/:tt_id/:session_id/session',

        component: lazy(() => import('src/views/OnlineExamSession'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating',

        component: lazy(() => import('src/views/OnlineExamSeating'))
      },
      {
        exact: true,
        path: '/app/exam/onlineExam/view/:exam_id',
        component: lazy(() => import('src/views/Tasks1/Group'))
      },
      {
        exact: true,
        path: '/app/examfee/reports',
        component: lazy(() => import('src/views/Reports1'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DayBook',
        component: lazy(() => import('src/views/Reports1/DayBookReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DayBookConvocation',
        component: lazy(() => import('src/views/Reports1/ConcocationDCR'))
      },
      {
        exact: true,
        path: '/app/admin/reports/RevaluationDayBook',
        component: lazy(() =>
          import('src/views/Reports1/RevaluationDayBookReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/appearinglistreport',
        component: lazy(() => import('src/views/Reports1/appearinglistreport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DeptStaffDetails',
        component: lazy(() => import('src/views/Reports1/DeptStaffDetails'))
      },
      {
        exact: true,
        path: '/app/admin/reports/SessionStaffDetails',
        component: lazy(() => import('src/views/Reports1/SessionStaffDetails'))
      },
      {
        exact: true,
        path: '/app/admin/reports/ia_seating_report',
        component: lazy(() => import('src/views/Reports1/IASeatingReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DutyAllotmentReport',
        component: lazy(() => import('src/views/Reports1/DutyAllotmentReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/result_analysis_department_wise_report',
        component: lazy(() =>
          import('src/views/Reports1/ResultAnalysisDepartmentWiseReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/result_analysis_course_wise_report',
        component: lazy(() =>
          import('src/views/Reports1/ResultAnalysisCourseWiseReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/exam_report',
        component: lazy(() => import('src/views/Reports1/ExamReport'))
      },

      {
        exact: true,
        path: '/app/admin/reports/FeeNotPaid',
        component: lazy(() => import('src/views/Reports1/FeeNotPaid'))
      },
      {
        exact: true,
        path: '/app/admin/reports/CandidateList',
        component: lazy(() => import('src/views/Reports1/CandidateList'))
      },
      {
        exact: true,
        path: '/app/exam/onlineExam',
        component: lazy(() => import('src/views/AvailableSoon'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:mode',

        component: lazy(() => import('src/views/OnlineExamSchedule'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/MembershipReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/MembershipReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Accomplishments/PositionHeldReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/AccomplishmentsReport/PositionHeldReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/COReportBatch',

        component: lazy(() => import('src/views/AllReports/CourseCOReportBatch'))
      },
      {
        exact: true,
        path: '/app/admin/reports/POPSOExpected',

        component: lazy(() => import('src/views/AllReports/POPSOExpected'))
      },
      {
        exact: true,
        path: '/app/admin/reports/ResultEntryLagReport',

        component: lazy(() =>
          import('src/views/AllReports/ResultEntryLagReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/FacultyCourseReport',
        guard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/FacultyCourseReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ExamReport',
guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/ExamReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/StudentRegReport',
        guard:HeadGuard,

        component: lazy(() => import('src/views/AllReports/StudentRegReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/CIEDiscrepancy',
        guard:HeadGuard,

        component: lazy(() => import('src/views/AllReports/CIEDiscrepancy'))
      },
      {
        exact: true,
        path: '/app/admin/reports/CourseExitSurveyReport',
        guard:HeadGuard,

        component: lazy(() =>
          import('src/views/AllReports/CourseExitSurveyReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/FeedbackReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/FeedBackReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/ResultAnalysisTerm',
        guard:HeadGuard,

        component: lazy(() => import('src/views/AllReports/ResultAnalysisTerm'))
      },
      {
        exact: true,
        path: '/app/admin/reports/ResultAnalysisDepartment',
        guard:HeadGuard,
        component: lazy(() =>
          import('src/views/AllReports/ResultAnalysisDepartment')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ResultAnalysisCourseWiseReport',
        guard:HeadGuard,

        component: lazy(() =>
          import('src/views/AllReports/ResultAnalysisCourseWiseReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ResultAnalysisDepartmentWiseReport',
        guard:HeadGuard,

        component: lazy(() =>
          import('src/views/AllReports/ResultAnalysisDepartmentWiseReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/facultyEmailReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/facultyreport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/StudentEmailReport',
        guard:HeadGuard,
        component: lazy(() => import('src/views/AllReports/studentreport'))
      },
     
      
     
      {
        exact: true,
        path: '/app/admin/course_category',
        guard: ReportsGuard,
        component: lazy(() =>
          import('src/views/CompanyView/CourseCategory/CourseCategoryList')
        )
      },

      {
        exact: true,
        path: '/app/admin/university',
        guard: ReportsGuard,
        component: lazy(() =>
          import('src/views/CompanyView/University/CourseCategoryList')
        )
      },
      {
        exact: true,
        path: '/app/admin/credits',
        guard: ReportsGuard,
        component: lazy(() =>
          import('src/views/CompanyView/Credits/CourseCategoryList')
        )
      },
      {
        exact: true,
        path: '/app/admin/aggrigation',
        guard: ReportsGuard,
        component: lazy(() =>
          import('src/views/CompanyView/Aggrigation/CourseCategoryList')
        )
      },
      {
        exact: true,
        path: '/app/admin/course_bank',
        guard: ReportsGuard,
        component: lazy(() => import('src/views/CourseBank'))
      },
      {
        exact: true,
        path: '/app/admin/course_rules',
        guard: ReportsGuard,
        component: lazy(() => import('src/views/CourseRules'))
      },
      {
        exact: true,
        path: '/app/admin/students/result_entry',
        guard: ReportsGuard,
        component: lazy(() => import('src/views/ResultEntry/AllStudents'))
      },
      {
        exact: true,
        path: '/app/admin/students/result_entry/:usn',
        guard: ReportsGuard,
        component: lazy(() => import('src/views/ResultEntry/AllStudents'))
      },

      // {
      //   exact: true,
      //   path: '/app/fee/collectfee/Order/:id',
      //   component: lazy(() => import('src/views/Mentors/Payments/CollectFee'))
      // },
      {
        exact: true,
        path: '/app/fee/order/:id',
        component: lazy(() =>
          import('src/views/Mentors/Payments/StudentsFee/OrderDetail')
        )
      },
      {
        exact: true,
        path: '/app/fee/order/exam/:id',
        component: lazy(() =>
          import('src/views/Mentors/Payments/StudentsFee/OrderDetailExam')
        )
      },
      {
        exact: true,
        path: '/app/fee/order/exam/:id/collectfee',
        component: lazy(() =>
          import(
            'src/views/Mentors/Payments/StudentsFee/OrderDetailExamCollectFee'
          )
        )
      },
      {
        exact: true,
        path: '/app/head/cal/:page_id',
        guard:HeadGuard,
        component: lazy(() => import('src/views/ListComponent'))
      },
      {
        exact: true,
        path: '/app/hr/cal/:page_id',
        guard:HRGuard,
        component: lazy(() => import('src/views/ListComponentHR'))
      },
      {
        exact: true,
        path: '/app/head/cal/academic_calendar',
        component: lazy(() => import('src/views/ListComponent'))
      },
      {
        exact: true,
        path: '/app/head/att_dashboard',
        component: lazy(() => import('src/views/AdminDashboard/StudentAttendance'))
      },
      {
        exact: true,
        path: '/app/head/calendar',
        guard: HODGuard,
        component: lazy(() => import('src/views/AdminCalendar'))
      },
      {
        exact: true,
        path: '/app/head/feedback',
        guard: HODGuard,
        component: lazy(() => import('src/views/AdminFeedback'))
      },
      {
        exact: true,
        path: '/app/head/schedules',
        guard: HODGuard,
        component: lazy(() => import('src/views/AcademicSchedule'))
      },
      {
        exact: true,
        path: '/app/head/ResultEntry',
        guard: HODGuard,
        component: lazy(() => import('src/views/ResultEntryStudent/Schedules'))
      },
      {
        exact: true,
        path: '/app/head/ResultEntry/student_wise/:id',
        guard: HODGuard,
        component: lazy(() => import('src/views/ResultEntryStudent'))
      },
      {
        exact: true,
        path: '/app/head/schedule/:id/:term',
        guard: HODGuard,
        component: lazy(() =>
          import('src/views/AcademicSchedule/IndexSchedule')
        )
      },
      {
        exact: true,
        path: '/app/head/feedback/detailview/:id',
        guard: HODGuard,
        component: lazy(() => import('src/views/AdminFeedback/FeedBack'))
      },
      
      {
        exact: true,
        path: '/app/hod/attainment',
        guard: HODGuard,
        component: lazy(() => import('src/views/Attainment'))
      },
      {
        exact: true,
        path: '/app/hod/attainment/semister',
        guard: HODGuard,
        component: lazy(() => import('src/views/Attainment/TermAttainment'))
      },
      {
        exact: true,
        path: '/app/hod/attainment/year',
        guard: HODGuard,
        component: lazy(() => import('src/views/Attainment/YearAttainment'))
      },
      {
        exact: true,
        path: '/app/hod/attainment/co',
        guard: HODGuard,
        component: lazy(() => import('src/views/Attainment/COAttainment'))
      },
      {
        exact: true,
        path: '/app/hod/attainment/criteria4',
        guard: HODGuard,
        component: lazy(() => import('src/views/Attainment/Criteria4'))
      },
      {
        exact: true,
        path: '/app/hod/attainment/YearWiseCGPA',
        guard: HODGuard,
        component: lazy(() => import('src/views/Attainment/YearWiseCGPA'))
      },
      {
        exact: true,
        path: '/app/hod/attainment/po',
        guard: HODGuard,
        component: lazy(() => import('src/views/Attainment/POAttainment'))
      },
     
    
    
      {
        exact: true,
        path: '/app/admin/coordinator/academics/schedules',
        guard: HODGuard,
        component: lazy(() =>
          import('src/views/CoOrdinator/Academics/Schedules')
        )
      },
      {
        exact: true,
        path: '/app/admin/coordinator/academics/schedules/:sched_id',
        guard: HODGuard,
        component: lazy(() =>
          import('src/views/CoOrdinator/Academics/Schedules/ScheduleDetail')
        )
      },
      {
        exact: true,
        path: '/app/admin/notifications',
        component: lazy(() => import('src/views/MessageLogs'))
      },
      {
        exact: true,
        path: '/app/admin/progresscards',
        component: lazy(() => import('src/views/ProgressCards'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/coordinator/academics/task_reset/faculties/:emp_id',
      //   guard: HODGuard,
      //   component: lazy(() =>
      //     import(
      //       'src/views/CoOrdinator/Academics/TaskReset/TaskResetDetailView'
      //     )
      //   )
      // },
      {
        exact: true,
        path: '/app/admin/student_bulk_data_update',
        guard: HODGuard,
        component: lazy(() =>
          import('src/views/CoOrdinator/StudentsMentorUpdate')
        )
      },
         
      {
        exact: true,
        path: '/app/coordinator/actions',
        guard: HODGuard,
        component: lazy(() => import('src/views/CoOrdinator'))
      },
     
      {
        exact: true,
        path: '/app/hod/faculties',
        guard: HODGuard,
        component: lazy(() =>
          import('src/views/CoOrdinator/Academics/TaskReset')
        )
      },
      {
        exact: true,
        path: '/app/head/obe',
        guard: HeadGuard,
        component: lazy(() => import('src/views/OBE'))
      },
      {
        exact: true,
        path: '/app/head/obe/reports',
        guard: HeadGuard,
        component: lazy(() => import('src/views/Attainment'))
      },
      {
        exact: true,
        path: '/app/head/obe/setup',
        guard: HeadGuard,
        component: lazy(() => import('src/views/OBESetup'))
      },
      {
        exact: true,
        path: '/app/head/obe/mapping',
        guard: HeadGuard,
        component: lazy(() => import('src/views/OBEMapping'))
      },
      {
        exact: true,
        path: '/app/head/obe/mapping/:batch_name/:course_code/:program/:academic_year/:term',
        guard: HeadGuard,
        component: lazy(() => import('src/views/OBEMapping/COPOMap'))
      },
      {
        exact: true,
        path: '/app/head/academicSetup',
        guard: HeadGuard,
        component: lazy(() => import('src/views/AcademicSetup/Layout'))
      },
      {
        exact: true,
        path: '/app/head/allStudents',
        guard: HeadGuard,
        component: lazy(() => import('src/views/StudentsList'))
      },
      {
        exact: true,
        path: '/app/heads/allStudents',
        guard: HeadGuard,
        component: lazy(() => import('src/views/AllStudents'))
      },
      {
        exact: true,
        path: '/app/head/promote_students/year',
        guard: HeadGuard,
        component: lazy(() => import('src/views/PromoteStudents'))
      },
      {
        exact: true,
        path: '/app/head/faculties',
        guard: HeadGuard,
        component: lazy(() => import('src/views/Employees'))
      },
      {
        exact: true,
        path: '/app/head/academics/task_reset/faculties/:emp_id',
        guard: HeadGuard,
        component: lazy(() =>
          import(
            'src/views/CoOrdinator/Academics/TaskReset/TaskResetDetailView'
          )
        )
      },
      {
        exact: true,
        path: '/app/head/student_detail/:usn',
        guard: HeadGuard,
        component: lazy(() => import('src/views/Mentors/StudentDetailView'))
      },
      {
        exact: true,
        path: '/app/head/student_detail/:id/:layout_id',
        guard: HeadGuard,
        component: lazy(() => import('src/views/Mentors/StudentFee'))
      },
      {
        exact: true,
        path: '/app/mentor/student_detail/:id/:layout_id',
        component: lazy(() => import('src/views/Mentors/StudentFee'))
      },
      {
        exact: true,
        path: '/app/classIncharge/student_detail/:id/:layout_id',
        component: lazy(() => import('src/views/Mentors/StudentFee'))
      },
      {
        exact: true,
        path: '/app/head/attendanceNew/:email/:id',
        guard: HeadGuard,
        component: lazy(() => import('src/views/AttendanceNew'))
      },
      {
        exact: true,
        path: '/app/head/attendanceNew/:course_code/:time_table_id/:section/:lab_batch/:id',
        guard: HeadGuard,
        component: lazy(() => import('src/views/AttendanceNew/ClassDetailView'))
      }
      // {
      //   component: () => <Redirect to="/404" />
      // }
    ]
  },

  // {
  //   path: '/app',
  //   guard: AuthGuard,
  //   layout: AllApps,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/app/admin/applications/detail',

  //       component: lazy(() => import('src/views/Applications/AppDetails'))
  //     },
  //   ]
  // },

  {
    path: '*',
    // layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
